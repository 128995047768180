<template>
  <div>
    <h2>Wedding Events</h2>

    <tabs
      :tabs="['Timeline', 'Map']"
    >
      <template #default="{ selectedTab }">
        <timeline
          v-if="selectedTab === 'Timeline'"
          :timeline="timeline"
        />

        <events-map
          v-else-if="selectedTab === 'Map'"
          class="events-map"
          :events="events"
        />
      </template>
    </tabs>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue';
import Timeline from '@/components/Timeline/index.vue';
import EventsMap from '@/components/Map/EventsMap.vue';
import events from '@/data/events.json';

export default {
  name: 'EventsPage',
  components: {
    Tabs,
    Timeline,
    EventsMap,
  },
  data: () => ({
    events,
  }),
  computed: {
    timeline() {
      return events.map((e) => ({
        open: true,
        hideCarrot: true,
        title: e.message,
        icon: this.iconFromEvent(e.key),
        date: e.start,
        type: 'calendar-event',
        event: e,
      }));
    },
  },
  methods: {
    iconFromEvent(eventKey) {
      switch (eventKey) {
        case 'rehearsal':
          return 'silverware-fork-knife';
        case 'wedding':
          return 'ring';
        case 'after-party':
          return 'glass-cocktail';
        case 'brunch':
          return 'egg-fried';
        default:
          return 'circle';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.events-map {
  left: -2rem;
  width: 100vw;
  height: 50vh;
}

/* Larger than tablet */
@media (min-width: 750px) {
  .events-map {
    left: calc((100vw - 750px + 4rem) / -2);
    width: 100vw;
    height: 70vh;
  }
}
</style>
