<template>
  <div class="tabs">
    <div class="tabs-container">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        class="button"
        :class="{'button-primary': tab === selectedTab}"
        @click="switchTab(tab)"
      >
        {{ tab }}
      </button>
    </div>
    <slot :selected-tab="selectedTab" />
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedTab: null,
  }),
  mounted() {
    [this.selectedTab] = this.tabs;
  },
  methods: {
    switchTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  .tabs-container {
    display: flex;
    margin-bottom: 2.5rem;

    button {
      flex: 1;

      &:not(:first-child) {
        border-left: 0;
      }
    }
  }
}
</style>
