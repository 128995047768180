<template>
  <div class="calendar-event">
    <div class="row">
      <h5>{{ event.message }}</h5>
    </div>
    <div class="row">
      <responsive-icon
        static-color
        name="map"
      />
      <span class="tooltip">
        <a
          target="_blank"
          :href="mapsUrl"
        >{{ locationName }}</a>
        <span
          v-if="locationAddress"
          class="tooltipText"
        >{{ locationAddress }}</span>
      </span>
    </div>
    <div
      v-if="event.start"
      class="row"
    >
      <responsive-icon
        static-color
        name="calendar"
      />
      <span>{{ date }}</span>
    </div>
    <div
      v-if="event.start"
      class="row"
    >
      <responsive-icon
        static-color
        name="clock-outline"
      />
      <span>{{ time }}</span>
    </div>
    <div class="row">
      <span class="description">
        {{ event.description }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import ResponsiveIcon from '@/components/ResponsiveIcon.vue';

export default {
  name: 'CalendarEvent',
  components: {
    ResponsiveIcon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locationName() {
      return this.event.location.split(',').shift().trim();
    },
    locationAddress() {
      return this.event.location.split(',').slice(1).join(',').trim();
    },
    mapsUrl() {
      return `https://www.google.com/maps/search/?api=1&query=${this.event.location}`;
    },
    time() {
      const start = moment(this.event.start).utcOffset('-07:00');
      const end = start.clone().add(...this.event.duration);

      return `${start.format('LT')} - ${end.format('LT')}`;
    },
    date() {
      const start = moment(this.event.start).utcOffset('-07:00');
      return start.format('ll');
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-event {
  .row {
    display: flex;
    flex-direction: row;
    margin: 5px;

    h5 {
      margin-bottom: 1rem;
    }

    span {
      margin-left: 5px;
      align-self: center;

      &.description {
        margin-top: 1rem;
      }
    }
  }
}
</style>
