<template>
  <GmapMap
    ref="gmap"
    :center="center"
    :zoom="zoom"
    :options="options"
    class="map"
  >
    <GmapMarker
      v-for="(m, index) in markers"
      :key="index"
      :position="m.position"
      :title="m.title"
      @click="toggleInfoWindow(m, index)"
    />
    <GmapInfoWindow
      v-if="selectedMarker"
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen=false"
    >
      <event-tool-tip :event="selectedMarker.event" />
    </GmapInfoWindow>
  </GmapMap>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';

import EventToolTip from '@/components/Map/EventToolTip.vue';

export default {
  name: 'EventsMap',
  components: {
    EventToolTip,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        zoomControl: true,
        scaleControl: true,
        fullscreenControl: true,
        disableDefaultUI: true,
      },
      center: { lat: 47.6558626, lng: -122.3104437 },
      zoom: 10,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      selectedMarker: null,

      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -40,
        },
      },
    };
  },
  computed: {
    markers() {
      return this.events
        .filter((e) => e.latitude && e.latitude)
        .map((e) => ({
          id: e.key,
          position: {
            lat: e.latitude,
            lng: e.longitude,
          },
          event: e,
        }))
        .concat({
          id: 'hotel',
          position: { lat: 47.6617384, lng: -122.3156575 },
          event: {
            message: 'Hotel Block',
            location: 'Residence Inn by Marriott Seattle University District, 4501 12th Ave NE, Seattle, WA 98105',
            description: 'This is the hotel',
          },
        });
    },
  },
  mounted() {
    this.$nextTick(() => this.fitBounds());
  },
  methods: {
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.selectedMarker = marker;

      // check if it's the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    async fitBounds() {
      const map = await this.$refs.gmap.$mapPromise;

      const google = gmapApi();

      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach((marker) => {
        bounds.extend(marker.position);
      });

      await map.fitBounds(bounds);
    },
    iconFromEvent(eventKey) {
      switch (eventKey) {
        case 'rehearsal':
          return 'silverware-fork-knife';
        case 'wedding':
          return 'ring';
        case 'after-party':
          return 'glass-cocktail';
        case 'brunch':
          return 'egg-fried';
        default:
          return 'circle';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  background-color: var(--background-color-static);
  color: var(--primary-color-static);
}
</style>

<!--eslint-disable-next-line vue-scoped-css/require-scoped-->
<style lang="scss">
button[aria-label="Close"] {
  top: 6px !important;
  right: 6px !important;
  width: 37px !important;
  height: 37px !important;

  img {
    width: 21px !important;
    height: 21px !important;
  }
}
</style>
